<template>
  <div>
    <van-nav-bar left-arrow left-text="返回" @click-left="goBack" title="查看全部网点" />
    <!-- <input type="date"> -->
    <div style="padding:6px 16px 0px;background-color:#fff;margin-top:17px">
      <div class="tit">预约明天安装工单量：{{installNumber}}</div>
      <div class="tit">预约明天维修工单量：{{repairNumber}}</div>
      <div class="tit">{{date}}</div>
    </div>
    <div style="margin-top:18px;background-color:#fff;padding-top:6px">
      <van-row style="padding:0 16px">
        <van-col span="2"><span class="listTitle">排名</span></van-col>
        <van-col span="10"><span class="listTitle">网点</span></van-col>
        <van-col span="6" @click="insFn"><span class="listTitle">安装工单量
            <van-icon :name="isIns?'arrow-down':'arrow-up'" />
          </span>
        </van-col>
        <van-col @click="repairFn" span="6"><span class="listTitle">维修工单量
            <van-icon :name="isrepair?'arrow-down':'arrow-up'" />
          </span></van-col>
      </van-row>
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list v-model="loading" :finished="finished" finished-text="没有更多了">
          <van-cell v-for="(item,i) in dataList" :key="i">
            <van-row>
              <van-col span="2">{{i+1}}</van-col>
              <van-col span="10">
                <div class="listTitle">{{item.name}}</div>
                <div class="listTitle">{{item.webNumber}}</div>
              </van-col>
              <van-col span="6">
                <div class="listTitle">{{item.install}}</div>
                <!-- <div v-if="item.wmr>0" style="color:#2da2df;font-size:12px"><img style="width:12px;height:12px" src="../../assets/icon/shangsheng.png" alt="">{{item.wmr}}%</div>
                <div v-if="item.wmr<0" style="color:#dd3a25;font-size:12px"><img style="width:12px;height:12px" src="../../assets/icon/down.png" alt="">{{-item.wmr}}%</div> -->
              </van-col>
              <van-col span="6">
                <div class="listTitle">{{item.repair}}</div>
                <!-- <div v-if="item.wcr>0" style="color:#2da2df;font-size:12px"><img style="width:12px;height:12px" src="../../assets/icon/shangsheng.png" alt="">{{item.wcr}}%</div>
                <div v-if="item.wcr<0" style="color:#dd3a25;font-size:12px"><img style="width:12px;height:12px" src="../../assets/icon/down.png" alt="">{{-item.wcr}}%</div> -->
              </van-col>
            </van-row>
          </van-cell>
        </van-list>
      </van-pull-refresh>

    </div>
  </div>
</template>

<script>
import { getUrlKey } from "@/utils/common";
export default {
  data() {
    return {
      installNumber: 0,
      repairNumber: 0,
      date: "",
      dataList: [],
      loading: false,
      finished: false,
      refreshing: false,
      isIns: false,
      isrepair: false,
    };
  },
  created() {
    this.getData();
    this.getDate();
    this.getList();
  },
  methods: {
    //维修筛选
    repairFn() {
      this.isrepair = !this.isrepair;
      if (this.isrepair) {
        this.dataList.sort((a, b) => {
          return b.repair - a.repair;
        }); /* 从大到小 */
      } else {
        this.dataList.sort((a, b) => {
          return a.repair - b.repair;
        }); /* 从小到大 */
      }
      // this.isrepair = false;
    },
    //安装筛选
    insFn() {
      this.isIns = !this.isIns;
      if (this.isIns) {
        this.dataList.sort((a, b) => {
          return b.install - a.install;
        }); /* 从大到小 */
      } else {
        this.dataList.sort((a, b) => {
          return a.install - b.install;
        }); /* 从小到大 */
      }
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;

      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.getList();
    },
    //获取列表数据
    getList() {
      let params = {
        openid: getUrlKey("openid", window.location.href),
        moduleId: "111111",
        type: "家用",
        startDate: "2022/09/28", // this.date,
        endDate: "2022/09/28", //this.date,
        website: "",
      };
      this.postRequest("/wechat-analysis/fees-day", params).then((res) => {
        let websitArr = res.data.map((v) => {
          return v.websit;
        });
        websitArr = [...new Set(websitArr)];
        let list = [];
        for (let i = 0; i < websitArr.length; i++) {
          list[i] = {
            webNumber: websitArr[i],
            name: 0,
            install: 0,
            repair: "",
          };
          for (let j = 0; j < res.data.length; j++) {
            if (websitArr[i] === res.data[j].websit) {
              list[i].name = res.data[j].webName;
            }
            if (
              websitArr[i] === res.data[j].websit &&
              res.data[j].btype === "材料"
            ) {
              list[i].install = res.data[j].ctorder;
            }
            if (
              websitArr[i] === res.data[j].websit &&
              res.data[j].btype === "维修"
            ) {
              list[i].repair = res.data[j].ctorder;
            }
          }
        }
        if (this.refreshing) {
          this.dataList = [];
          this.refreshing = false;
        }
        if (res.data.length === 0) {
          this.dataList = [];
          this.finished = true;
        }
        this.dataList = list;
        this.loading = false;
        if (this.dataList.length >= websitArr.length) {
          this.finished = true;
        }
      });
    },
    //获取今天日期
    getDate() {
      let time = new Date();
      this.date = ` ${time.getFullYear()}/${
        time.getMonth() + 1 > 9
          ? time.getMonth() + 1
          : "0" + (time.getMonth() + 1)
      }/${time.getDate() > 9 ? time.getDate() : "0" + time.getDate()}`;
    },
    //获取标题数据
    getData() {
      let params = {
        openid: getUrlKey("openid", window.location.href),
        moduleId: "11",
        type: "家用",
      };
      this.postRequest("/wechat-analysis/today-summary", params).then((res) => {
        console.log(res);
        this.installNumber = res.data[8].c1;
        this.repairNumber = res.data[9].c1;
      });
    },
    goBack() {
      this.$router.back();
    },
  },
};
</script>

<style lang="scss" scoped>
.tit {
  font-weight: 700;
  font-size: 14px;
}
.listTitle {
  font-weight: 700;
  font-size: 12px;
}
</style>